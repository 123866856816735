




























import { Component, Vue, Prop } from 'vue-property-decorator';

import { WorkPlatformService } from '@WorkPlatform/api/index';

import { namespace } from 'vuex-class';

import { service } from '@cloudpivot-hermes/nail-free-login';

import { isPhone } from '@WorkPlatform/common/utils';
import { appForward } from '@WorkPlatform/service/flow-center';
import { EClientType } from '@WorkPlatform/common/const';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'app-page',
  components: {
    AppImage: () => import('./app-image.vue'),
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) list!: any[];

  @workPlatformModule.State('recentIds') recentIds: any;

  @workPlatformModule.Action('setRecentIds') setRecentIds: any;

  @workPlatformModule.Action('setOnlineApps') setOnlineApps: any;

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  clickHandler (item: any) {
    appForward(item, EClientType.PC);
  }

  setRecentApp (id: string) {
    const ids: string | null = this.recentIds;
    let idsArr: any[] = [];
    if (Array.isArray(ids)) {
      idsArr = ids.filter((item: any) => item !== id);
    }
    idsArr.unshift(id);
    this.setRecentIds(idsArr);
  }

  get isMobile () {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      navigator.userAgent,
    );
  }
}
